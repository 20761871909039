import { colors } from 'variables';
import React from 'react';

import tile1 from 'assets/images/production/image8.png';
import tile1x2 from 'assets/images/production/image8@2x.png';
import tile2 from 'assets/images/production/image9.png';
import tile2x2 from 'assets/images/production/image9@2x.png';
import tile3 from 'assets/images/production/image10.png';
import tile3x2 from 'assets/images/production/image10@2x.png';

import questionIllustration from 'assets/images/production/illustration.svg';

import capabilities_image1 from 'assets/images/production/grafika1.png';
import capabilities_image1x2 from 'assets/images/production/grafika1@2x.png';
import capabilities_image2 from 'assets/images/production/grafika2.png';
import capabilities_image2x2 from 'assets/images/production/grafika2@2x.png';
import capabilities_image3 from 'assets/images/production/grafika3.png';
import capabilities_image3x2 from 'assets/images/production/grafika3@2x.png';

import tab1 from 'assets/images/production/grafika4.png';
import tab1x2 from 'assets/images/production/grafika4@2x.png';
import tab2 from 'assets/images/production/grafika5.png';
import tab2x2 from 'assets/images/production/grafika5@2x.png';
import tab3 from 'assets/images/production/grafika7.png';
import tab3x2 from 'assets/images/production/grafika7@2x.png';
import tab4 from 'assets/images/production/grafika8.png';
import tab4x2 from 'assets/images/production/grafika8@2x.png';
import tab5 from 'assets/images/production/grafika9.png';
import tab5x2 from 'assets/images/production/grafika9@2x.png';

export const productionData = {
   slug: 'dla-firm-produkcyjnych',
   header: [
      <strong>Unikaj błędów</strong>,
      ' na produkcji i ',
      <strong>oszczędzaj czas</strong>,
   ],
   subHeader: 'Dla firm produkcyjnych',
   featureCards: [
      {
         image: tile1,
         imageX2: tile1x2,
         color: colors.lightViolet,
         headerText: [
            <strong>Rejestruj towar</strong>,
            ' z pomocą kodów kreskowych lub QR',
         ],
         description:
            'Kontroluj półprodukty wchodzące na linie produkcyjne, eliminuj błędy związane z ręcznym oznakowaniem towarów.',
      },
      {
         image: tile2,
         imageX2: tile2x2,
         color: colors.lightViolet,
         headerText: [
            <strong>Śledź w systemie</strong>,
            ' zadania dla wszystkich partii towaru',
         ],
         description:
            'Zatwierdzaj tylko zakończone etapy produkcji i otrzymuj alerty o problemach i niezgodnościach.',
      },
      {
         image: tile3,
         imageX2: tile3x2,
         color: colors.lightViolet,
         imagePosition: 'right',
         headerText: [
            <strong>Zarządzaj uprawnieniami</strong>,
            ' i dostępem do systemu',
         ],
         description:
            'Usprawniaj pracę poprzez nadanie uprawień pracownikom tylko takich, jakich naprawdę potrzebują.',
      },
   ],
   questionTitle: [
      'Z ',
      <strong>LabControl</strong>,
      ' zaplanujesz pracę, zorganizujesz pracowników i uporządkujesz dokumentację',
   ],
   questionIllustration: questionIllustration,
   questionClouds: [
      {
         question: [
            'Szukasz sposobu ',
            <strong>
               jak łatwo kontrolować produkowane partie i zamówienia?
            </strong>,
         ],
         questionLink: 'kontrola',
      },
      {
         question: [
            <strong>Chciałbyś by informację o zmianach</strong>,
            ' trafiały do Ciebie na czas?',
         ],
         questionLink: 'zmiany',
      },
      {
         question: [
            <strong>Zdarzyło Ci się wycofanie sporej partii towaru</strong>,
            ' z powodu błędu na produkcji, który trafił do Ciebie zbyt późno?',
         ],
         questionLink: 'wycofanie',
      },
   ],
   capabilitiesCards: [
      {
         sectionId: 'zmiany',
         image: capabilities_image1,
         imageX2: capabilities_image1x2,
         headerText: [
            <strong>Organizuj</strong>,
            ' zadania i prace zespołu ',
            <strong>przy pomocy harmonogramu</strong>,
         ],
         description:
            'Dodawaj nowe zlecenia, kontroluj zadania na poszczególnych etapach produkcji. Przypisuj zadania do odpowiednich osób z zespołu.',
         reverseRow: false,
      },
      {
         sectionId: 'wycofanie',
         image: capabilities_image2,
         imageX2: capabilities_image2x2,
         headerText: [
            'Wyeliminuj pomyłki dzięki ',
            <strong>alertowaniu o błędach</strong>,
         ],
         description:
            'Wskaż osoby, które mają być natychmiast powiadamiane o sytuacjach niezgodnych z planem.',
         reverseRow: true,
      },
      {
         sectionId: 'kontrola',
         image: capabilities_image3,
         imageX2: capabilities_image3x2,
         headerText: [
            <strong>Planuj z wyprzedzeniem</strong>,
            ' by uniknąć ',
            <strong>zaskoczenia</strong>,
         ],
         description:
            'Wrzucaj zlecenia i zadania w wolne miejsce w hamonogramie, unikniesz kolizji na produkcji lub przestojów i strat finansowych.',
         reverseRow: false,
      },
   ],
   functionalitiesTitle: ['Główne ', <strong>funkcjonalności</strong>],
   functionalitiesTabs: [
      {
         title: 'Harmonogram Pracy',
         text: {
            bold: 'Dodawaj zlecenie i zadania ',
            normal:
               'w harmonogramie na każdy dzień. Sprawdzaj status ich wykonania w czasie rzeczywistym. Zatwierdzaj zrealizowane i reaguj na te, które są niezgodne z planem lub wytyczoną przez Ciebie normą.',
         },
         image: tab1,
         imageX2: tab1x2,
      },
      {
         title: 'Zarządzenia zasobami',
         text: {
            bold: 'Zrób listy,',
            normal:
               ' dodawaj pracowników i nadawaj im uprawnienia. Kontroluj stany magazynowe oraz sprzęt, z którego korzysta Twój zespół. Ustaw przypomnienia o przeglądach technicznych i donawianiu uprawnień.',
         },
         image: tab2,
         imageX2: tab2x2,
      },
      {
         title: 'Zarządzenia dokumentami',
         text: {
            bold: 'Wprowadź dokumenty,',
            normal:
               ' z których korzystają pracownicy podczas tworzenia sprawozdań czy specyfikacji technicznych. Klient dostanie niezbędne dokumenty kilka razy szybciej, a Twój pracownik zajmie się kolejnym zleceniem.',
         },
         image: tab3,
         imageX2: tab3x2,
      },
      {
         title: 'Raportowanie',
         text: {
            bold: 'Twórz własne raporty ',
            normal:
               ' z danych, których potrzebujesz. Exportuj do .xls, .pdf lub innego formatu i przesyłaj dalej lub po prostu udostępnij go w systemie wybranym osobom w firmie.',
         },
         image: tab4,
         imageX2: tab4x2,
      },
      {
         title: 'Tworzenie szablonów',
         text: {
            bold: 'Twórz szablony zleceń i zadań',
            normal:
               ' do codziennej pracy swoich pracowników. Dopracuj każdy formularz do klienta, lub jeśli uważasz, że jest ich za dużo - zunifikuj je i unikaj błędów w wypełnianiu.',
         },
         image: tab5,
         imageX2: tab5x2,
      },
   ],
   implementationTitle: [
      'Poznaj historię budowy produktu',
      <br />,
      'w ',
      <strong>KPT ScaleUp</strong>,
   ],
};
