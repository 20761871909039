import CapabilitiesSection from 'components/shared/CapabilitiesSection';
import FeatureSection from 'components/shared/FeatureSection';
import FunctionalitiesSection from 'components/shared/FunctionalitiesSection';
import HeaderButtons from 'components/shared/HeaderButtons';
import ImplementationSection from 'components/shared/ImplementationSection';
import PackagesSection from 'components/shared/PackagesSection';
import PageHeader from 'components/shared/PageHeader';
import QuestionsSection from 'components/shared/QuestionsSection';
import SEO from 'components/shared/SEO';
import solutions from 'data/solutions';
import React from 'react';

export default function Solution({ pageContext }) {
   const data = solutions[pageContext.slug];
   return (
      <>
         <SEO title={`${data.subHeader}`} />
         <PageHeader
            as="header"
            headerText={data.header}
            subHeader={data.subHeader}
         >
            <HeaderButtons />
         </PageHeader>

         <FeatureSection cards={data.featureCards} />
         <QuestionsSection
            title={data.questionTitle}
            clouds={data.questionClouds}
            illustration={data.questionIllustration}
         />
         <CapabilitiesSection cards={data.capabilitiesCards} />
         <FunctionalitiesSection
            title={data.functionalitiesTitle}
            tabs={data.functionalitiesTabs}
         />
         <ImplementationSection title={data.implementationTitle} />
         <PackagesSection />
      </>
   );
}
