import tile1 from 'assets/images/laboratory/image11.png';
import tile1x2 from 'assets/images/laboratory/image11@2x.png';
import tile2 from 'assets/images/laboratory/image12.png';
import tile2x2 from 'assets/images/laboratory/image12@2x.png';
import tile3 from 'assets/images/laboratory/image13.png';
import tile3x2 from 'assets/images/laboratory/image13@2x.png';

import questionIllustration from 'assets/images/laboratory/illustration.svg';

import capabilities_image1 from 'assets/images/laboratory/grafika1.png';
import capabilities_image1x2 from 'assets/images/laboratory/grafika1@2x.png';
import capabilities_image2 from 'assets/images/laboratory/grafika2.png';
import capabilities_image2x2 from 'assets/images/laboratory/grafika2@2x.png';
import capabilities_image3 from 'assets/images/laboratory/grafika3.png';
import capabilities_image3x2 from 'assets/images/laboratory/grafika3@2x.png';

import tab1 from 'assets/images/laboratory/grafika4.png';
import tab1x2 from 'assets/images/laboratory/grafika4@2x.png';
import tab2 from 'assets/images/laboratory/grafika5.png';
import tab2x2 from 'assets/images/laboratory/grafika5@2x.png';
import tab3 from 'assets/images/laboratory/grafika6.png';
import tab3x2 from 'assets/images/laboratory/grafika6@2x.png';
import tab4 from 'assets/images/laboratory/grafika7.png';
import tab4x2 from 'assets/images/laboratory/grafika7@2x.png';
import tab5 from 'assets/images/laboratory/grafika8.png';
import tab5x2 from 'assets/images/laboratory/grafika8@2x.png';

import React from 'react';
import { colors } from 'variables';

export const laboratoryData = {
   slug: 'dla-laboratoriow',
   header: [
      'Zamień papierowe formularze na cyfrowe ',
      <strong>w swoim Laboratorium</strong>,
   ],
   subHeader: 'Dla laboratoriów',
   featureCards: [
      {
         image: tile1,
         imageX2: tile1x2,
         color: colors.lightGreen,
         headerText: [
            'Nie pracuj na gotowcach i twórz własne szablony ',
            <strong>karty badań</strong>,
         ],
         description:
            'Dopasuj system do swojego laboratorium, a nie odwrotnie. Zamień swoje formularze papierowe w cyfrowe dostępne online.',
      },
      {
         image: tile2,
         imageX2: tile2x2,
         color: colors.lightGreen,
         headerText: [
            <strong>Śledź próbkę</strong>,
            ' od momentu pobrania do sprawozdania',
         ],
         description:
            'Koniec z papierowymi protokołami przyjęcia i kartami wyników. Monitoruj próbkę w systemie na jednym formularzu.',
      },
      {
         image: tile3,
         imageX2: tile3x2,
         color: colors.lightGreen,
         headerText: [
            'Przydzielaj zadania i monitoruj ',
            <strong>efektywność</strong>,
         ],
         description:
            'Przypisuj zadania według doświadczenia, tempa pracy i kwalifikacji poszczególnych pracowników.',
      },
   ],
   questionTitle: [
      'Z ',
      <strong>LabControl</strong>,
      ' zaplanujesz pracę, zorganizujesz pracowników i uporządkujesz dokumentację',
   ],
   questionIllustration: questionIllustration,
   questionClouds: [
      {
         question: [
            'Czy przygotowanie sprawozdania dla klienta ',
            <strong>zajmuje Ci za dużo czasu?</strong>,
         ],
         questionLink: 'sprawozdania',
      },
      {
         question: [
            'Czy organizacja pracy zajmuje Ci więcej czasu niż ',
            <strong>realizacja zadań?</strong>,
         ],
         questionLink: 'organizacja',
      },
      {
         question: [
            <strong>
               Czy zdarzyło Ci się, że zgubienie papierowego formularza
            </strong>,
            ' oznaczało wykonanie badania od początku?',
         ],
         questionLink: 'zgubienie',
      },
   ],
   capabilitiesCards: [
      {
         sectionId: 'organizacja',
         image: capabilities_image1,
         imageX2: capabilities_image1x2,
         headerText: [
            'Organizuj prace laborantom i ',
            <strong>przypisuj zadania do osoby</strong>,
         ],
         description:
            'Wrzucaj na plan zadania przypisując je konkretnym osobom. Monitoruj ich status oraz kto dokonywał zmian w zadaniach.',
         reverseRow: false,
      },
      {
         sectionId: 'zgubienie',
         image: capabilities_image2,
         imageX2: capabilities_image2x2,
         headerText: [
            'Elektronicznego formularza próbki ',
            <strong>nie zgubisz</strong>,
         ],
         description:
            'Stwórz własne szablony próbek i miej do nich dostęp na dowolnym urządzeniu oraz w dowolnym miejscu i czasie',
         reverseRow: true,
      },
      {
         sectionId: 'sprawozdania',
         image: capabilities_image3,
         imageX2: capabilities_image3x2,
         headerText: [
            'Przenoś dane do sprawozdania ',
            <strong>jednym kliknięciem</strong>,
         ],
         description:
            'Zatwierdzaj badania blokowo dzięki czemu automatycznie wygenerujesz sprawozdanie',
         reverseRow: false,
      },
   ],
   functionalitiesTitle: ['Główne ', <strong>funkcjonalności</strong>],
   functionalitiesTabs: [
      {
         title: 'Harmonogram Pracy',
         text: {
            normal:
               'Dodawaj zlecenia i zadania w harmonogrami na każdy dzień. Sprawdzaj status ich wykonania w czasie rzeczywistym. Zatwierdzaj zrealizowane i reaguj na te, które są niezgodne z planem lub wytyczoną przez Ciebie normą',
         },
         image: tab1,
         imageX2: tab1x2,
      },
      {
         title: 'Zarządzenia zasobami',
         text: {
            normal:
               'Twórz bazy pracowników. Odbieraj alerty o zbliżających się terminach wzorcowania, kalibracji urządzeń i sprzętów. Monitoruj stany magazynowe odczynników.',
         },
         image: tab2,
         imageX2: tab2x2,
      },
      {
         title: 'Zarządzenia dokumentami',
         text: {
            normal:
               'Stwórz bazę dokumentów norm i specyfikacji. Utwórz bazę wiedzy postępowania z groźnymi dla zdrowia odczynnikami lub specyficznymi wymogami norm PCA.',
         },
         image: tab3,
         imageX2: tab3x2,
      },
      {
         title: 'Raportowanie',
         text: {
            normal:
               'Twórz własne raporty z dowlonie wybranymi informacjami. Filtruj dane z całej historii badań w dowolny sposób i eksportuj do XLS lub CSV.',
         },
         image: tab4,
         imageX2: tab4x2,
      },
      {
         title: 'Tworzenie szablonów',
         text: {
            normal:
               'Przygotuj szablony najczęściej zlecanych u siebie badań i ustaw w systemie automatyczną częstotliwość ich realizacji. Przyspieszysz ich realizację i unikniesz błędu pominięcia badań cyklicznych.',
         },
         image: tab5,
         imageX2: tab5x2,
      },
   ],
   implementationTitle: [
      'Poznaj historię budowy produktu',
      <br />,
      'w ',
      <strong>KPT ScaleUp</strong>,
   ],
};
