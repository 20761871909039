import { colors } from 'variables';
import React from 'react';

import tile1 from 'assets/images/construction/image14.png';
import tile1x2 from 'assets/images/construction/image14@2x.png';
import tile2 from 'assets/images/construction/image15.png';
import tile2x2 from 'assets/images/construction/image15@2x.png';
import tile3 from 'assets/images/construction/image16.png';
import tile3x2 from 'assets/images/construction/image16@2x.png';

import questionIllustration from 'assets/images/construction/illustration.svg';

import capabilities_image1 from 'assets/images/construction/grafika1.png';
import capabilities_image1x2 from 'assets/images/construction/grafika1@2x.png';
import capabilities_image2 from 'assets/images/construction/grafika2.png';
import capabilities_image2x2 from 'assets/images/construction/grafika2@2x.png';
import capabilities_image3 from 'assets/images/construction/grafika3.png';
import capabilities_image3x2 from 'assets/images/construction/grafika3@2x.png';

import tab1 from 'assets/images/construction/grafika4.png';
import tab1x2 from 'assets/images/construction/grafika4@2x.png';
import tab2 from 'assets/images/construction/grafika5.png';
import tab2x2 from 'assets/images/construction/grafika5@2x.png';
import tab3 from 'assets/images/construction/grafika6.png';
import tab3x2 from 'assets/images/construction/grafika6@2x.png';
import tab4 from 'assets/images/construction/grafika7.png';
import tab4x2 from 'assets/images/construction/grafika7@2x.png';
import tab5 from 'assets/images/construction/grafika8.png';
import tab5x2 from 'assets/images/construction/grafika8@2x.png';

export const constructionData = {
   slug: 'dla-firm-budowlanych',
   header: [
      <strong>Kontroluj prace budowlane</strong>,
      ' na wszystkich płaszczyznach',
   ],
   subHeader: 'Dla firm budowlanych',
   featureCards: [
      {
         image: tile1,
         imageX2: tile1x2,
         color: colors.lightBlue,
         headerText: ['Dokumentuj dostawy i ', <strong>pilnuj jakości</strong>],
         description:
            'Wprowadzaj w czasie rzeczywistym dostawy i je archiwizuj, miej podgląd nad asortymentem jakim dysponujesz.',
      },
      {
         image: tile2,
         imageX2: tile2x2,
         color: colors.lightBlue,
         headerText: [
            <strong>Pozwól śledzić projekt</strong>,
            ' i być częścią procesu.',
         ],
         description:
            'Zarządzaj zmianami, śledź rewizję projektów i jej status, udostępnij projekt dla Gościa.',
      },
      {
         image: tile3,
         imageX2: tile3x2,
         color: colors.lightBlue,
         headerText: [
            'Przydzielaj zadania, monitoruj efekty i ',
            <strong>pilnuj budżetu</strong>,
         ],
         description:
            'Przekaż zadania pracownikom w chmurze i zarządzaj efektywniej zasobami ludzkimi i sprzętowymi.',
      },
   ],
   questionTitle: [
      'Z ',
      <strong>LabControl</strong>,
      ' zaplanujesz pracę, zorganizujesz pracowników i uporządkujesz dokumentację',
   ],
   questionIllustration: questionIllustration,
   questionClouds: [
      {
         question: [
            'Czy przygotowanie raportów dziennych ',
            <strong>zajmuje Ci za dużo czasu?</strong>,
         ],
         questionLink: 'raport',
      },
      {
         question: [
            <strong>Chciałbyś by informację o zmianach</strong>,
            ' trafiały do Ciebie na czas?',
         ],
         questionLink: 'zmiany',
      },
      {
         question: [
            <strong>Zdarzyło Ci się zapomnieć</strong>,
            ' o wykonaniu odpowiedniej ilosci badań na budowie?',
         ],
         questionLink: 'badania',
      },
   ],
   capabilitiesCards: [
      {
         sectionId: 'raport',
         image: capabilities_image1,
         imageX2: capabilities_image1x2,
         headerText: [
            'Przygotuj i eksportuj ',
            <strong>raport dla Inwestora</strong>,
            ' od ręki',
         ],
         description:
            'Weryfikuj powierzone zadania z intuicyjnym wypełnianiem raportu dziennego poprzez gotowy szablon. Oszczędzaj czas!',
         reverseRow: false,
      },
      {
         sectionId: 'badania',
         image: capabilities_image2,
         imageX2: capabilities_image2x2,
         headerText: [
            'Dopilnuj ',
            <strong>odpowiedniej ilości badań</strong>,
            ' na budowie',
         ],
         description:
            'LabControl pozwala wyłapać błędy bez ciągłego wertowania Specyfikacji i Wymagań Technicznych',
         reverseRow: true,
      },
      {
         sectionId: 'zmiany',
         image: capabilities_image3,
         imageX2: capabilities_image3x2,
         headerText: [
            <strong>Zorganizuj swoją budowę</strong>,
            ' i planuj z wyprzedzeniem ',
         ],
         description:
            'LabControl pozwoli Ci mieć kontrolę nad procesem budowlanym, umożliwi Ci śledzenie kontraktu i powierzonych zadań dla podległych pracowników.',
         reverseRow: false,
      },
   ],
   functionalitiesTitle: ['Główne ', <strong>funkcjonalności</strong>],
   functionalitiesTabs: [
      {
         title: 'Harmonogram budowy',
         text: {
            bold: 'Planuj zadania dla swojego Projektu',
            normal:
               ' w czasie rzeczywistym, gdzie każdy widzi realizację bieżących zadań i ma do nich pełen dostęp i wiedzę. Unikniesz braku informacji w zespole. Daj się poprowadzić przy pomocy LabControl.',
         },
         image: tab1,
         imageX2: tab1x2,
      },
      {
         title: 'Zarządzenia zasobami',
         text: {
            bold: 'Zarządzaj na budowie tym co faktycznie masz,',
            normal:
               ' miej pełną wiedzę na temat tego, co posiadasz: wykaz sprzętu, wykaz pracowników z odpowiednimi i wymaganymi prawem uprawnieniami, szkoleniami. Czy wiesz ile materiału Ci zostało na budowie?',
         },
         image: tab2,
         imageX2: tab2x2,
      },
      {
         title: 'Zarządzenia dokumentami',
         text: {
            bold: 'Miej kontrolę nad tym, co jest zatwierdzone,',
            normal:
               ' co wymaga ponownej weryfikacji. Czy w porę złożyłeś rewizję? Niech każdy wie, kto z zespołu odpowiada za dany dokument.',
         },
         image: tab3,
         imageX2: tab3x2,
      },
      {
         title: 'Raportowanie',
         text: {
            mixed: [
               'Czy ',
               <strong>
                  raport dzienny od inwestora jest w porę złożony i kto go
                  złożył?
               </strong>,
               ' Teraz poprzez intuicyjne wypełnianie systemu, oddelegowany pracownik może się skupić nad kolejną rzeczą i skrócić czas gromadzenia danych.',
            ],
         },
         image: tab4,
         imageX2: tab4x2,
      },
      {
         title: 'Tworzenie szablonów',
         text: {
            mixed: [
               <strong>W każdym momencie możesz</strong>,
               ' dla swojego Projektu ',
               <strong>
                  stworzyć nowe rozwiązanie w postaci szablonu i formularza.
               </strong>,
               ' Wszystkie te, z których już nie korzystasz przesuń do Archiwum.',
            ],
         },
         image: tab5,
         imageX2: tab5x2,
      },
   ],
   implementationTitle: [
      'Poznaj historię budowy produktu',
      <br />,
      'w ',
      <strong>KPT ScaleUp</strong>,
   ],
};
